import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "local-registration-container" }
const _hoisted_2 = { class: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_route_tabs = _resolveComponent("route-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Breadcrumb, {
        class: "title-bg",
        dataSource: []
      })
    ]),
    _createVNode(_component_route_tabs, { "route-name": "Local Registration Registrationg Result" })
  ]))
}